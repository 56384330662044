import Vue from "vue";
import router from "./router";
import * as filters from "../filters.js";
import store from "./store";
import i18n from "../i18n.js";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import LoadScript from "vue-plugin-load-script";

import {
  Pagination,
  Dialog,
  Autocomplete,
  Input,
  Radio,
  RadioButton,
  RadioGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  Tag,
  DatePicker,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tree,
  Alert,
  Icon,
  Row,
  Col,
  Upload,
  Card,
  Checkbox,
  CheckboxGroup,
  Link,
  Divider,
  Image,
  MessageBox,
  Message,
  Empty,
  Descriptions,
  DescriptionsItem,
  Steps,
  Step,
  ColorPicker,
  TimeSelect,
  TimePicker,
  Notification,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Timeline,
  TimelineItem,
  Tooltip,
  InputNumber,
  Collapse,
  CollapseItem,
  Carousel,
  CarouselItem,
  Progress,
} from "element-ui";

// Vue.config.productionTip = false;

const AuthTopBar = () => import("./components/nav/AuthTopBar.vue");
const NavHeader = () => import("./components/nav/NavHeader.vue");
const NavSidebar = () => import("./components/nav/NavSidebar.vue");

window.Vue = Vue;

// configure language
locale.use(lang);

Vue.component(Steps.name, Steps);
Vue.component(Step.name, Step);
Vue.component(Pagination.name, Pagination);
Vue.component(Dialog.name, Dialog);
Vue.component(Autocomplete.name, Autocomplete);
Vue.component(Input.name, Input);
Vue.component(Radio.name, Radio);
Vue.component(RadioButton.name, RadioButton);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(Switch.name, Switch);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(OptionGroup.name, OptionGroup);
Vue.component(Button.name, Button);
Vue.component(ButtonGroup.name, ButtonGroup);
Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(Tabs.name, Tabs);
Vue.component(TabPane.name, TabPane);
Vue.component(Tag.name, Tag);
Vue.component(Tree.name, Tree);
Vue.component(Alert.name, Alert);
Vue.component(Icon.name, Icon);
Vue.component(Row.name, Row);
Vue.component(Col.name, Col);
Vue.component(Upload.name, Upload);
Vue.component(Card.name, Card);
Vue.component(Checkbox.name, Checkbox);
Vue.component(CheckboxGroup.name, CheckboxGroup);
Vue.component(Link.name, Link);
Vue.component(Divider.name, Divider);
Vue.component(Image.name, Image);
Vue.component(Empty.name, Empty);
Vue.component(Descriptions.name, Descriptions);
Vue.component(DescriptionsItem.name, DescriptionsItem);
Vue.component(ColorPicker.name, ColorPicker);
Vue.component(TimeSelect.name, TimeSelect);
Vue.component(TimePicker.name, TimePicker);
Vue.component(Dropdown.name, Dropdown);
Vue.component(DropdownItem.name, DropdownItem);
Vue.component(DropdownMenu.name, DropdownMenu);
Vue.component(Timeline.name, Timeline);
Vue.component(TimelineItem.name, TimelineItem);
Vue.component(Tooltip.name, Tooltip);
Vue.component(InputNumber.name, InputNumber);
Vue.component(Collapse.name, Collapse);
Vue.component(CollapseItem.name, CollapseItem);
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
Vue.component(Progress.name, Progress);

Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;

Vue.use(LoadScript);

const app = new Vue({
  el: "#app",
  i18n,
  router,
  store,
  components: {
    AuthTopBar,
    NavHeader,
    NavSidebar,
  },
});
