import Vue from "vue";

Vue.filter("lowercase", function (value) {
  return value.toLowerCase();
});

Vue.filter("uppercase", function (value) {
  return value.toUpperCase();
});

Vue.filter("capitalize", function (value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("titlecase", function (value) {
  return value.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
});

Vue.filter("roundToTwoDecimalPlaces", function (value) {
  return parseFloat(value).toFixed(2);
});

Vue.filter("roundToThreeDecimalPlaces", function (value) {
  return parseFloat(value).toFixed(3);
});
